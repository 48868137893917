import { HashLink } from "react-router-hash-link";

function NavElement(props) {
  return (
    <HashLink className="nav-hash" to={props.to} smooth>
      <li>{props.name}</li>
    </HashLink>
  );
}

function Navbar() {
  return (
    <ul>
      <NavElement to="#One" name="About Us" />
      <NavElement to="#Two" name="Commitment Clients" />
      <NavElement to="#Three" name="Textile & Leather" />
      <NavElement to="#Five" name="Textile Chemicals" />
      <NavElement to="#contacts" name="Contact" />
    </ul>
  );
}

export default Navbar;
