import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

/* COMPONENTS */
// import Header from "./components/Header";
import VideoComp from "./components/VideoComp";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";
import Clients from "./components/Clients";
import Cards from "./components/Cards";
import Form from "./components/Form";
import TextileOne from "./components/TextileOne";
import TextileTwo from "./components/TextileTwo";
import NavbarResponsive from "./components/NavbarResponsive";

function App() {
  return (
    <Router>
      <div className="contenedor">
        <NavbarResponsive />
        {/* <Header /> */}
        <VideoComp />
        <AboutUs />
        <Clients />
        <Cards />
        <TextileOne />
        <TextileTwo />
        <Form />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
