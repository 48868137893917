import { ReactComponent as LogoColor } from "../resources/logo-color.svg";
import * as AiIcons from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

function Footer() {
  return (
    <>
      <footer className="row text-center footer">
        <LogoColor className="logoFooter" />
        <div className="row footer-info">
          <div className="col container-info">
            <a href="mailto:" className="footer-link">
              <AiIcons.AiOutlineMail className="footer-icon" />
              <hr className="info-divisor" />
              <p className="footer-text">info@rexacotrading.com</p>
            </a>
          </div>
          <div className="col container-info">
            <HashLink to="#contacts" className="footer-link">
              <AiIcons.AiOutlineEnvironment className="footer-icon" />
              <hr className="info-divisor" />
              <p className="footer-text">
                535 Baltimore Pike, Glen Mills, PA 19342, United States
              </p>
            </HashLink>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6> Copyright © 2022 All rights reserved | Revalcode </h6>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
