import Parallax from "react-rellax";

function TextileTwo() {
  return (
    <section id="Five" className="container-fluid textileTwo-container">
      <div className="row textileTwo">
        <div className="col-12 col-sm-12 col-md col-lg textileTwo-img"></div>
        <div className="col-12 col-sm-12 col-md col-lg textileTwo-content">
          <Parallax speed={3}>
            <article>
              <h2>
                Textile Chemicals <hr className="line" />
              </h2>
              <p>
                Textile Chemicals A wide range of specialty chemicals are
                available to add value across the board, from fiber to fabric.
                We offer chemical products for the pretreatment, dyeing,
                printing and finishing of textiles. From the wide range of
                pretreatment chemicals we have chemicals that are 100%
                biodegradable.
              </p>
              <p>
                Our antifoaming Agents in dyeing chemicals give excellent
                results. Bio polishing enzymes, Dye Fixing agents, Optical
                Brightening Agents, Peroxide Stabilizer and more have unique
                features to deliver results. An expert team promptly delivers to
                specific challenges faced by customers and this is possible only
                because of the all round expertise gathered through the years.
              </p>
            </article>
          </Parallax>
        </div>
      </div>
    </section>
  );
}

export default TextileTwo;
