import emailjs from "emailjs-com";
import { init } from "@emailjs/browser";
import swal from "sweetalert";

init("oZ_tmvc63Sh8IiWm8");

function Form() {
  const exitoEnvio = () => {
    swal({
      title: "Success!!",
      text: "Your email has been sent. ",
      icon: "success",
      closeModal: true,
      timer: 2000,
    });
  };
  const falloEnvio = () => {
    swal({
      title: "Error!!",
      text: "your emial could not be sent",
      icon: "error",
      closeModal: true,
      timer: 2000,
    });
  };

  function SendMail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_09v2aks",
        "template_rxwngth",
        e.target,
        "oZ_tmvc63Sh8IiWm8"
      )
      .then((res) => {
        console.log(res.text);
        e.target.reset();
        exitoEnvio();
      })
      .catch((err) => {
        console.log(err.text);
        falloEnvio();
      });
  }

  return (
    <div className="container-fluid contacts">
      <section
        id="contacts"
        className="row justify-content-center topSmall contacts Four"
      >
        <div className="col-md-7 col-lg-8">
          <h4 className="text-6 contactosT">Contact Us</h4>
          <hr className="line-3" />
          <form className="p-4" onSubmit={SendMail}>
            <div className="row g-3">
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter your name..."
                  name="name"
                  required
                />
              </div>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter your  last name..."
                  name="last_name"
                  required
                />
              </div>
              <div className="col-12">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email..."
                  name="email"
                  required
                />
              </div>
              <div className="col-12 message">
                <textarea
                  type="text"
                  className="form-control"
                  id="message"
                  placeholder="Your message here..."
                  name="message"
                  required
                />
              </div>
            </div>
            <div className="d.flex text-center">
              <button type="submit" className="send-btn btn  mt-4">
                Send
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Form;
