import Parallax from "react-rellax";

function TextileOne() {
  return (
    <section id="Three" className="container-fluid textilOne-container">
      <div className="row textilOne-us">
        <div className="col-12 col-sm-12 col-md col-lg textilOne-content">
          <Parallax speed={6}>
            <article>
              <h2>
                Textile & Leather <hr className="line" />
              </h2>
              <p>
                With a wide range of dyes for cellulosics and polyester, Rexaco
                Trading Inc. offers BAT solutions to the demands of the textile
                industry.
              </p>
              <p>
                For Polyester, our high quality disperse dyes are the perfect
                solution. Our auxiliaries complement our dyes throughout the
                entire process for best results, reduced environmental footprint
                and comfortable finishes.
              </p>
              <ul>
                <li>Benefits at a Glance</li>
                <li>Wide range of shades available</li>
                <li>High brilliancy</li>
                <li>Very good fastness performance</li>
                <li>Competitive product cost and cost saving solutions</li>
                <li>Water and energy saving solutions</li>
                <li>Compliance with international regulations</li>
                <li>Compliance with voluntary standards</li>
              </ul>
            </article>
          </Parallax>
        </div>
        <div className="col-12 col-sm-12 col-md col-lg textilOne-img"></div>
      </div>
    </section>
  );
}

export default TextileOne;
