import Parallax from "react-rellax";

function Clients() {
  return (
    <section id="Two" className="container-fluid clients-container">
      <div className="row clients">
        <div className="col-12 col-sm-12 col-md col-lg clients-img"></div>
        <div className="col-12 col-sm-12 col-md col-lg clients-content">
          <Parallax speed={3}>
            <article>
              <h2>
                Commitment to our clients <hr className="line" />
              </h2>
              <p>
                At Rexaco Trading Inc. quality comes naturally. We've nurtured
                teams of experts whose priority is to provide customers with
                exactly what they expect: safe products to use in their
                business.
              </p>
              <p>
                Our laboratories and manufacturing facilities adhere to strict
                procedures and guidelines set forth by certification bodies,
                legal requirements, and the high expectations we set for
                ourselves. There is simply no compromise when it comes to
                providing customers with reliable chemistry.
              </p>
              <p>
                Safe and reliable chemistry produces quality products. For us,
                there is no compromise. This is what makes Rexaco Trading Inc.
                your trusted supplier of quality chemicals and colorants.
              </p>
            </article>
          </Parallax>
        </div>
      </div>
    </section>
  );
}

export default Clients;
