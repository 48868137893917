import Navbar from "./Navbar";
/* import { HashLink } from "react-router-hash-link"; */

function Header() {
  return (
    <header>
      <div className="row headerColor">
        <div className="col-4 container-logo">
          {/*  <HashLink to="#One" smooth>
            <img
              className="logo"
              alt="logo"
              src={require("../resources/logo-BandW.png")}
            />
          </HashLink> */}
        </div>
        <div className="col-8 navBar">
          <Navbar />
        </div>
      </div>
    </header>
  );
}

export default Header;
