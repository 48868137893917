import Quality from "../resources/card-one.svg";
import Chemistry from "../resources/card-two.svg";
import Environment from "../resources/card-three.svg";

const CardContent = (props) => {
  return (
    <>
      <div className="content">
        <img src={props.image} alt={props.alt} />
        <h3 className="card-title">{props.title}</h3>
      </div>
    </>
  );
};

const Cards = () => {
  return (
    <section id="Three" className="cards">
      <div className="container container-cards">
        <div className="row ">
          <div className="carta col-4 col-sm-4 col-md col-lg">
            <CardContent
              image={Quality}
              alt="Quality commitment"
              title="Quality commitment"
            />
          </div>

          <div className="carta col-4 col-sm-4 col-md col-lg">
            <CardContent
              image={Chemistry}
              alt="Safe and reliable chemistry"
              title="Safe and reliable chemistry"
            />
          </div>
          <div className="carta col-4 col-sm-4 col-md col-lg">
            <CardContent
              image={Environment}
              alt="Increasing environmental standards"
              title="Increasing environmental standards"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cards;
