import BackgroundVid from "../resources/Video.m4v";
import Header from "./Header";
import logo from "../resources/logo-BandW.png";

function VideoComp() {
  return (
    <div className="row videoColor">
      <Header />
      <video className="videoBc" muted autoPlay loop type="video/m4v">
        <source src={BackgroundVid} />
      </video>
      <img src={logo} alt="Rexaco" className="logo-video" />
    </div>
  );
}

export default VideoComp;
