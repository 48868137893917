export const SidebarData = [
  {
    title: "About Us",
    path: "#One",
    cName: "nav-text",
  },
  {
    title: "Commitment Clients",
    path: "#Two",
    cName: "nav-text",
  },
  {
    title: "Textile & Leather",
    path: "#Three",
    cName: "nav-text",
  },
  {
    title: "Textile Chemicals",
    path: "#Five",
    cName: "nav-text",
  },
  {
    title: "Contact",
    path: "#contacts",
    cName: "nav-text",
  },
];
