import Parallax from "react-rellax";

function AboutUs() {
  return (
    <section id="One" className="container-fluid about-container">
      <div className="row about-us">
        <div className="col-sm-12 col-md col-lg about-content">
          <Parallax speed={6}>
            <article>
              <h2>
                About Us <hr className="line" />
              </h2>
              <p>
                Chemistry can be challenging, and finding reliable sources is
                exhausting. That's why we develop an ongoing consultative
                relationship with our clients, helping them solve problems, gain
                an advantage over their competitors and become more profitable.
              </p>
              <p>
                We primarily manufacture custom chemicals and colorants that
                require strict specifications and the highest levels of quality
                and consistency. Our people are passionate about providing the
                best service in the chemical industry by being better informed
                and better trained.
              </p>
              <p>
                Rexaco Trading Inc. is continually growing as we expand into new
                locations and develop new products and technology to better meet
                the needs of our customers. Our focus is on long-term
                sustainable growth rather than short-term profit. This is what
                allows us to make you, our customers, our number one priority.
              </p>
            </article>
          </Parallax>
        </div>
        <div className="col-sm-12 col-md col-lg about-img"></div>
      </div>
    </section>
  );
}

export default AboutUs;
